import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <p className="f7">© 2020 Leading Risk Solutions</p>
            <br/>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
